import { LOAD_SEARCH, SET_ALL_RESULTS } from '../store/actions';

const initialState = {
  all: [],
  filters: {},
  results: []
}

interface SearchActionType {
  type: string,
  all: any,
  results: any
}

const search = (state = initialState, action: SearchActionType) => {
  switch (action.type) {
    case SET_ALL_RESULTS:
      console.log(JSON.stringify(action));
      return Object.assign({}, state, {all : action.all});
    case LOAD_SEARCH:
      console.log(JSON.stringify(action));
      return Object.assign({}, action.results);
    default:
      return state;
  }
}

export default search;