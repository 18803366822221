import React, { ChangeEventHandler } from 'react';
import './Form.css';

export interface Item {
  label: string,
  value: string
}

interface Props {
  name: string,
  onChange: ChangeEventHandler<HTMLSelectElement>,
  value: string,
  className: string,
  options: Item[]
}
class Select extends React.Component<Props> {
  render() {
    return (
      <select className={this.props.className} name={this.props.name} value={this.props.value} onChange={this.props.onChange}>
        {
          this.props.options.map(function(option: Item, index: number) {
           return <option key={index} value={option.value}>{option.label}</option>;
          })
        }
      </select>
    );
  }
}

export default Select;
