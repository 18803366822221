import {
  SET_COOKIE_POLICY
} from '../store/cookieActions';

const initialState = {
  accepted: false
}

interface CookiePolicyAction {
  type: string
}
const cookiePolicy = (state = initialState, action: CookiePolicyAction) => {
  switch (action.type) {
    case SET_COOKIE_POLICY:
      console.log('SET_COOKIE_POLICY ');
      return Object.assign({}, state, {
          accepted: true
      });
 
    default:
      return state;
  }
}

export default cookiePolicy;