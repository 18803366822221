import React from 'react';
import './MessageBox.css'

interface Props {
  type?: string
}

class MessageBox extends React.Component<Props> {
  render() {
    return (
      <div className={ 'MessageBox MessageBox--' + (this.props.type || 'error')}>
        {this.props.children}
      </div>
    );
  }
}

export default MessageBox;
