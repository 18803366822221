import React, { Component } from 'react';
import './App.css';
import {
  Route,
  Switch
} from 'react-router-dom';
import HomePage from './home/HomePage';
import ApplyPage from './application/ApplyPage';
import SearchPage from './search/SearchPage';
import DragPage from './dragndrop/DragPage';
import CreditPage from './credit/CreditPage';
import LoginPage from './login/LoginPage';
import LogoutPage from './login/LogoutPage';

class Main extends Component {
  render() {
    return (
      <main>
        <Switch>
          <Route exact path='/' component={HomePage}/>
          <Route exact path='/applyPage' component={ApplyPage}/>
          <Route exact path='/searchPage' component={SearchPage}/>
          <Route exact path='/dragPage' component={DragPage}/>
          <Route exact path='/credit' component={CreditPage}/>
          <Route exact path='/login' component={LoginPage}/>
          <Route exact path='/logout' component={LogoutPage}/>
        </Switch>
      </main>
    );
  }
}

export default Main;
