import cookie from 'cookie';
const SET_COOKIE_POLICY = 'app/SET_COOKIE_POLICY';

const acceptCookiePolicy = () => {
  return (dispatch: Function) => {
    document.cookie = cookie.serialize('accepted', 'true', { maxAge: 60 * 60 * 24 });
    dispatch(setCookiePolicy());
  }
}

const setCookiePolicy = () => {
  return {
    type: SET_COOKIE_POLICY
  }
}

export {
  acceptCookiePolicy,
  setCookiePolicy,
  SET_COOKIE_POLICY
}