import React from 'react';
import { DropTarget } from 'react-dnd';

import { ItemTypes } from './ItemTypes';

function collect(connect, monitor) {
  return {
    connectDragTarget: connect.DropTarget,
    isOver: monitor.isOver()
  }
}

const spec = {
  // Describes how the drop target reacts to the drag and drop events
  drop(props, monitor, component) {
    console.log('drop');
    return undefined; 
  },
  hover(props, monitor, component) {
    console.log('hover');
  }
};

function Square( {black, children} ) {
  const fill = black ? 'black' : 'white';
  const stroke = black ? 'white' : 'black';

  return (
    <div style={{
      backgroundColor: fill,
      color: stroke,
      width: '100%',
      height: '100%'
    }}>
      {children}
    </div>
  );
}

export default DropTarget(ItemTypes.KNIGHT, spec, collect)(Square);