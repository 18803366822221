import React from 'react';
import { connect } from 'react-redux'
import './Loader.css';

interface Props {
  isLoading: boolean;
}

class Loader extends React.Component<Props> {
  render() {
    const isVisible = this.props.isLoading;

    return (
      <div>
        {isVisible &&
          <div>
            <div className="shadow"/>
            <div className="spinner-container">
              <svg className="spinner" viewBox="0 0 50 50">
                <path d="M25,3 A21,21 0 0,1 47,25"/>
              </svg>
            </div>
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(newState: any) {
  return { isLoading: newState.loading.isLoading };
}

export default connect(mapStateToProps)(Loader);