import React from 'react';
import { connect } from 'react-redux'

import '../App.css';
import Server from './Server';
import TodoList from './TodoList';
import Accordian from '../common/accordian/Accordian';
import AccordianItem from '../common/accordian/AccordianItem';
import { setIsLoading } from '../store/loadingActions';
import MessageBox from '../common/messagebox/MessageBox';

interface Props {
  dispatch: Function;
}

interface HomePageState {
  message: string;
  error: any;
}

class HomePage extends React.Component<Props, HomePageState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      message: "default"
    };
  }

  componentDidMount() {
    this.props.dispatch(setIsLoading(true));
    fetch("/static/data/cars.json")
      .then(res => res.json())
      .then(
        (result) => {
          this.props.dispatch(setIsLoading(false));
          this.setState({
            message: result.message
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.props.dispatch(setIsLoading(false));
          this.setState({
            error
          });
        }
      )
  }

  render() {
    return (
      <div className="container-fluid">
        <br/>
        <div className="row">
          <div className="col-sm-offset-4 col-sm-4">
            <MessageBox type="error">
              Message
            </MessageBox>
            <Accordian>
              <AccordianItem title="Server Components">
                <Server name="server1"/>
                <Server name="server2"/>
              </AccordianItem>
              <AccordianItem title="TodoList">
                <TodoList></TodoList>
              </AccordianItem>
            </Accordian>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(HomePage);
