const SET_IS_LOADING = 'app/SET_IS_LOADING';

function setIsLoading(isLoading: boolean) {
    return {
    type: SET_IS_LOADING,
    isLoading
  }
}

export {
  setIsLoading,
  SET_IS_LOADING
}