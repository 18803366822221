import React from 'react';
import { connect } from 'react-redux'
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import '../App.css';
import './ApplyPage.css';
import '../form/Form.css';
import MessageBox from '../common/messagebox/MessageBox';
import Address from './Address';
import { Input, Select, TextArea } from '../form';
import { Item } from '../form/Select';
import { loadApplication } from '../store/applicationsActions';

interface Props {
  dispatch: Function
}

interface ApplyPageState {
  errors: any[],
  addresses: any[],
  firstname : string,
  lastname : string,
  dateOfBirth : any,
  quoteNumber: string,
  additionalInformation: string,
  existingCustomer: string
}

const existingCustomerOptions: Item[] = [
  {
    label: '',
    value: ''
  },
  {
    label: 'Yes',
    value: 'Y'
  },
  {
    label: 'No',
    value: 'N'
  }
];

class ApplyPage extends React.Component<Props, ApplyPageState> {
  constructor(props: Props) {
    super(props);

    // Set initial form values
    this.state = {
        errors: [],
        addresses: [],
        firstname : '',
        lastname : '',
        dateOfBirth : '',
        quoteNumber: '',
        additionalInformation: '',
        existingCustomer: ''
    }

    let action = loadApplication({
      firstname : "Fred",
      lastname : "Smith",
      dateOfBirth : "1984-04-21",
      quoteNumber: "87465354",
      addresses : [
        {
          key: "1",
          houseNumber: "1",
          road: "Acacia Avenue",
          town: "Reading",
          county: "Berkshire",
          postcode: "RG22 4FB"
        },
        {
          key: "2",
          houseNumber: "2",
          road: "Acacia Avenue",
          town: "Reading",
          county: "Berkshire",
          postcode: "RG22 4FB"
        }
      ],
      additionalInformation: "Additional information"
    });

    this.props.dispatch(action);
  }

  componentWillReceiveProps(nextProps: any) {
    console.log('componentWillReceiveProps: ' + nextProps); // 
    this.setState(
      nextProps
    );
    this.setState(
      { errors: [] }
    );
  }

  render() {
    return (
     <form className="well">
        <h1>Application details</h1>
        <div>
        {
          this.state.errors.map((error, index) => {
            return <MessageBox>{error.text}</MessageBox>
          })
        }
        </div>
        <div className="form-group row">
          <label className="col-xs-4 text-left">First name</label>
          <div className="col-xs-3">
            <Input name="firstname" value={this.state.firstname} onChange={this.onChange}/>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-xs-4 text-left">Last name</label>
          <div className="col-xs-3">
            <Input name="lastname" value={this.state.lastname} onChange={this.onChange}/>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-xs-4 text-left">Date of birth</label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="col-xs-2 text-left">
              <KeyboardDatePicker margin="normal" className="DatePicker form-control form-control-sm Input" format="dd/MM/yyyy" value={this.state.dateOfBirth} onChange={this.handleDateOfBirthChange}/>
            </div>
          </MuiPickersUtilsProvider>
        </div>
        {
          this.state.addresses.map((address, index) => {
            return <Address {...address} removeHandler={this.handleRemoveAddress} id={address.key}/>
          })
        }
        <div className="form-group row">
          <div className="col-xs-offset-4 col-xs-2 text-left">
            <button className="btn btn-info btn-xs" name="addAddress" onClick={this.handleAddAddress}>Add Address</button>
          </div>
        </div>
        <br/>
        <div className="form-group row">
          <label className="col-xs-4 text-left">Additional Information</label>
          <TextArea className="col-xs-4 text-left" name="additionalInformation" value={this.state.additionalInformation} onChange={this.onChange}/>
        </div>
        <div className="form-group row">
          <label className="col-xs-4 text-left">Existing customer?</label>
          <Select className="col-xs-1 Input" name="existingCustomer" value={this.state.existingCustomer} onChange={this.onChange}
          options={existingCustomerOptions}
          ></Select>
        </div>

        <div><p>{JSON.stringify(this.state)}</p></div>
        <br/>
        <button onClick={this.handleSubmit}>Submit</button>
      </form>
    );
  }

  handleDateOfBirthChange = (date: any) => {
    this.setState( { dateOfBirth: date})
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    this.setState( { [event.target.name] : event.target.value} as any );
  }

  handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    alert('submitted : ' + JSON.stringify(this.state));
    // TODO submit
    this.setState( {errors: this.validatePage() } );
  }

  validatePage() {
    let errors = [];
    if (!this.state.firstname) {
      errors.push({ text: "First name is required" });
    }
    // TODO:
    errors.push({ text: "Addresses must be provided for the last 3 years" });
    if (!this.state.existingCustomer) {
      errors.push({ text: "Existing customer is required" });
    }

    return errors;
  }


  handleAddAddress = (event: React.MouseEvent) => {
    event.preventDefault();
    let newAddresses = this.state.addresses;
    let newAddress = { key: newAddresses.length + 1 };
    newAddresses.push(newAddress);
    console.log('addAddress ' + JSON.stringify(newAddresses));
    this.setState( { addresses: newAddresses } );
  }

  handleRemoveAddress = (event: React.MouseEvent, key: string) => {
    event.preventDefault();
    console.log( 'handleRemoveAddress: ' + key);
    if( key) {
      let index = Number.parseInt(key, 10) - 1;
      let newAddresses = this.state.addresses;
      newAddresses.splice(index, 1);
      this.setState( { addresses: newAddresses } );
    }
  }
}

function mapStateToProps(newState: any) {
  return newState.applications.currentApplication;
}

export default connect(mapStateToProps)(ApplyPage);
