import React, { ChangeEventHandler } from 'react';
import './Form.css';

interface Props {
  name: string,
  onChange: ChangeEventHandler<HTMLTextAreaElement>,
  className: string,
  value: string
}

class TextArea extends React.Component<Props> {
  render() {
    return (
      <textarea className={this.props.className} name={this.props.name} value={this.props.value} 
        onChange={this.props.onChange} />
    );
  }
}

export default TextArea;
