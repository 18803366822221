import React, { Component } from 'react';
import './CreditFilters.css';
import '../App.css';

class CreditFilters extends Component {

  render() {
    return (
     <div className="container">
       <div className="row">
          <div className="FilterFieldGroup">
            <label className="FilterLabel">Forename</label>
            <input className="FilterInputField" name="forename"></input>
          </div>
          <div className="FilterFieldGroup">
            <label className="FilterLabel">Surname</label>
            <input className="FilterInputField" name="surname"></input>
          </div>
          <div className="FilterFieldGroup">
            <label className="FilterLabel">Reference</label>
            <input className="FilterInputField" name="reference"></input>
          </div>
          <div className="FilterFieldGroup">
            <label className="FilterLabel">Phone</label>
            <input className="FilterInputField" name="phone"></input>
          </div>
        </div>
        <div className="row">
          <button className="btn btn-primary btn-sm">Search</button>
        </div>
      </div>
    );
  }
}

export default CreditFilters;
