import React, { Component } from 'react';
import Todo, { TodoItem } from './Todo';
import './TodoList.css';

interface TodoListState {
  items: TodoItem[];
}
class TodoList extends Component<any, TodoListState> {
  constructor(props: any) {
    super(props);
    this.state = { items: [ { id: 1, text: "Hello"}, {id: 2, text:"World"}] };
  };

  onClickAdd = (e: React.MouseEvent<HTMLButtonElement>) => {
    // TODO: https://reactjs.org/docs/refs-and-the-dom.html#legacy-api-string-refs
    const newItemRef = this.refs.newItem as any; 
    if( newItemRef.value) {
      this.state.items.push( {id: this.getId(), text: newItemRef.value })
      this.setState(this.state);
      newItemRef.value = '';
    }
  };

  onClickRemove = (id: number) => {
    const newItems = this.state.items.filter( item => {
      return item.id !== id;
    });
    this.setState({'items': newItems});
  };

  getId = () => {
    const maxId = Math.max.apply(null, this.state.items.map(item => item.id));
    return maxId + 1;
  };

  render() {
    return (
      <div>
        {this.state.items.map(item => (
          <Todo key= {item.id} item={item} remove={this.onClickRemove}></Todo>
        ))}
        <br/>
        <input ref="newItem" type="text" placeholder="Enter an item"/>
        <button onClick={this.onClickAdd}>Add</button>
      </div>
    );
  };
}

export default TodoList;
