import {
  LOGIN_STATE,
  LOGOUT_STATE,
  LOGIN_FAILURE
} from '../store/loginActions';

const initialState = {
  loggedInUser: undefined
}

interface LoginActionType  {
  type: string,
  username: string,
  message: string
}

const login = (state = initialState, action: LoginActionType) => {
  switch (action.type) {
    case LOGIN_STATE:
      console.log('LOGIN_STATE ' + action.username);
      return Object.assign({}, state, {
          loggedInUser: action.username
      });
    case LOGOUT_STATE:
      console.log('LOGOUT_STATE ');
      return Object.assign({}, state, {
          loggedInUser: undefined
      });
    case LOGIN_FAILURE:
      console.log('LOGIN_FAILURE ' + action.message);
      return Object.assign({}, state, {
          message: action.message,
          errors: [action.message]
      });
  
      default:
      return state;
  }
}

export default login;