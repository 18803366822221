import Axios from "axios";

const API_URL = 'https://sample-api.jbpdev.net/v1/auth';
const LOGIN_STATE = 'app/LOGIN_STATE';
const LOGOUT_STATE = 'app/LOGOUT_STATE';
const LOGIN_FAILURE = 'app/LOGIN_FAILURE';

interface credentials {
  username: string,
  password: string
}

export const login = ({username, password}: credentials) => {
  return (dispatch: Function) => {
    Axios.post(API_URL, {
      username,
      password
    },{
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
        sessionStorage.setItem('token', res.headers.token);
        dispatch(loginState(username));
    }).catch(err => {
      if ( err && err.response && err.response.data) {
        dispatch(loginFailure(err.response.data.error));
      } else {
        dispatch(loginFailure(err.message));
      }
    })
  }
}

function loginState(username: string) {
  return {
    type: LOGIN_STATE,
    username
  }
}

function loginFailure(message: string) {
  return {
    type: LOGIN_FAILURE,
    message
  }
}

function logoutState() {
  return {
    type: LOGOUT_STATE
  }
}

export {
  loginState,
  logoutState,
  loginFailure,
  LOGIN_STATE,
  LOGOUT_STATE,
  LOGIN_FAILURE
}