const initialState = {
  currentApplication: {
    firstname : "Initial Value",
    addresses: []
  }
}

interface ApplicationActionType {
  type: string,
  application: any
}

const applications = (state = initialState, action: ApplicationActionType) => {
  switch (action.type) {
    case 'application/LOAD_APPLICATION':
      return Object.assign({}, state, {
          currentApplication: action.application
      });
    default:
      return state;
  }
}

export default applications