import React from 'react';

export type TodoItem = {
  id: number,
  text: string
}

interface Props {
  item: TodoItem,
  remove: Function
}

class Todo extends React.Component<Props> {
  onRemove = () => {
    this.props.remove(this.props.item.id);
  };

  // Render a single Todo item
  render() {
    return (
      <div>
        <button className="Todo-item" onClick={this.onRemove}>{this.props.item.text}</button>
      </div>
    );
  };
}

export default Todo;
