import React, { Component } from 'react';
import CreditFilters from './CreditFilters';
import '../App.css';

class CreditPage extends Component {
  render() {
    return (
      <div>
        <CreditFilters/>
      </div>
    );
  }
}

export default CreditPage;
