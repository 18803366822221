import React, { Component } from 'react';
import { connect } from 'react-redux'
import { loadSearch, setAllSearch } from '../store/actions';
import '../App.css';

class SearchPage extends Component {
  constructor(props) {
    super(props);

    // Set initial form values
    this.state = {
      manufacturer: "",
      results: [],
      errors: []
    }

    let action = loadSearch({
      manufacturer : "Audi"
    });

    this.props.dispatch(action);
  }

  componentDidMount() {
    fetch("/data/cars.json")
      .then(res => res.json())
      .then(
        (result) => {
          this.props.dispatch(setAllSearch(result));
        },
        (error) => {
          this.props.dispatch(setAllSearch([]));
          this.setState({
            error
          });
        }
      )
  }

  render() {
    const hasResults = this.state.results.length;
    const table = hasResults ? (<table className="Table">
      <thead>
        <tr className="TableHeading"><th>Manufacturer</th><th>Model</th><th>Price</th></tr>
      </thead>
      <tbody>
      {
        this.state.results.map((row, index) => {
          return <tr key={index} className="TableRow"><td>{row.manufacturer}</td><td>{row.model}</td><td>{row.price}</td></tr>
        })
      }
      </tbody>
    </table>) : null;

    return (
     <form className="well">
        <h1>Find a car</h1>
        <div>
          <label>Manufacturer</label>
          <select name="manufacturer" onChange={this.handleManufacturerChange} value={this.state.manufacturer}>
            <option value=""></option>
            <option value="Audi">Audi</option>
            <option value="BMW">BMW</option>
            <option value="Ford">Ford</option>
          </select>
        </div>

        { table }

        <br/>
        <button className="btn btn-primary" onClick={this.handleSearch}>Search</button>
      </form>
    );
  }

  handleManufacturerChange = (event) => {
    this.setState( { manufacturer: event.target.value});
  }

  handleSearch = (event) => {
    event.preventDefault();
    console.log('handleSubmit : ' + JSON.stringify(this.state));
    // TODO run a search
    const results = this.state.all.filter(item => {
      return (!this.state.manufacturer || (item.manufacturer === this.state.manufacturer));
    })
    let action = loadSearch(
      {
        manufacturer: this.manufacturer,
        results: results
      }
    )

    this.props.dispatch(action);
  }
  
  static getDerivedStateFromProps(nextProps, state) {
    let result =  { 
      results: nextProps.results ? nextProps.results : []
    };
    if ( nextProps.all ) {
      result.all = nextProps.all;
    }
    return result;
  }
}

function mapStateToProps(newState) {
  return newState.search;
}

export default connect(mapStateToProps)(SearchPage);
