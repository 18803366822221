import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import cookie from 'cookie';

import Notification from './notification/Notification';
import Header from './common/header/Header';
import Main from './Main';
import Menu from './common/header/Menu';
import Loader from './common/loader/Loader';
import { setIsLoading } from './store/loadingActions';
import './App.css';
import './common.scss';
import { setCookiePolicy } from './store/cookieActions';

interface Props {
  dispatch: Function;
}

class App extends React.Component<Props> {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Header/>
          <Menu/>
          <Loader/>
          <Main/>
          <Notification text="This site uses cookies for your comfort and convenience. Please indicate your acceptance of this use of cookies."></Notification>
        </div>
      </BrowserRouter>
    );
  }

  componentDidMount() {
    let cookies = cookie.parse(document.cookie);
    if (cookies.accepted) {
      this.props.dispatch(setCookiePolicy());
    }
    this.props.dispatch(setIsLoading(true));

    // Hard code hiding of loader after 3 seconds
    setTimeout(() => {
      this.props.dispatch(setIsLoading(false));
    }, 3000);
  }
}

// call connect() to get access to dispatch method
export default connect()(App);
