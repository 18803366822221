import { SET_IS_LOADING } from '../store/loadingActions';

const initialState = {
  isLoading: true
}

interface LoadingActionType {
  type: string,
  isLoading: boolean
}
const loading = (state = initialState, action: LoadingActionType) => {
  switch (action.type) {
    case SET_IS_LOADING:
      console.log('SET_IS_LOADING ' + action.isLoading);
      return Object.assign({}, state, {
          isLoading: action.isLoading
      });
    default:
      return state;
  }
}

export default loading;