import React from 'react';
import { connect } from 'react-redux'
import { login } from '../store/loginActions';
import '../App.css';
import './LoginPage.css';

interface Props {
  history: any,
  message: string,
  loggedInUser: string,
  dispatch: Function,
  errors: any[],
  username: string,
  password: string
}

type LoginPageState = {
  errors: any[],
  username: string,
  password: string
}

class LoginPage extends React.Component<Props, LoginPageState> {
  constructor(props: Props) {
    super(props);

    // Set initial form values
    this.state = {
      errors: [],
      username: '',
      password: ''
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    console.log('componentWillReceiveProps: ' + JSON.stringify(nextProps));

    // If now logged in, navigate to home page
    if (nextProps.loggedInUser) {
      this.props.history.push("/");
    }
    // Otherwise, update this component's state
    this.setState(
      nextProps
    );
    let errors = [];
    if (nextProps.message) {
      errors.push( { text: nextProps.message} );
    };
    this.setState(
      { errors: errors }
    );
  }

  render() {
    return (
     <form className="well">
        <h1>Please login</h1>

        <div>
        {
          this.state.errors.map((error, index) => {
            return <p className="text-danger">{error.text}</p>
          })
        }
        </div>
        <div className="form-group row">
          <label className="col-xs-2 col-xs-offset-3 text-left">Username</label>
          <div className="col-xs-3">
            <input name="username" className="form-control form-control-sm" value={this.state.username} onChange={this.handleUserNameChange}/>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-xs-2 col-xs-offset-3 text-left">Password</label>
          <div className="col-xs-3">
            <input name="password" type="password" className="form-control form-control-sm" value={this.state.password} onChange={this.handlePasswordChange}/>
          </div>
        </div>

        <button onClick={this.handleSubmit}>Submit</button>
      </form>
    );
  }

  handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState( { username: event.target.value});
  }

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState( { password: event.target.value});
  }

  handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const errors = this.validatePage();
    if(errors.length === 0) {
      this.props.dispatch(login( {username: this.state.username, password: this.state.password }));
    }
    this.setState( {errors: errors } );
  }

  validatePage() {
    let errors = [];
    if(!this.state.username) {
      errors.push({ text: 'username must be supplied'});
    }
    if(!this.state.password) {
      errors.push({ text: 'password must be supplied'});
    }
    return errors;
  }
}

function mapStateToProps(newState: any) {
  return newState.login;
}

export default connect(mapStateToProps)(LoginPage);
