import React from 'react';
import './AccordianItem.css';

interface AccordianItemType {
  title: string
}

interface AccordianItemState {
  visible: boolean
}
class AccordianItem extends React.Component<AccordianItemType, AccordianItemState> {
  constructor(props: AccordianItemType) {
    // console.log('AccordianItem props :' + JSON.stringify(props));
    super(props);
    // if(props) {
    //   this.state = props;
    // }
    this.state = {visible : false};
  }

  render() {
    const style = {
      display: this.state.visible ? "block" : "none"
    };
    return (
      <div className="AccordianItem">
        <div className="AccordianItemTitle" onClick={this.handleClick}>{this.props.title}</div>
        <div style={style}>
          {this.props.children}
        </div>
      </div>
    );
  }

  handleClick = (event: React.MouseEvent) => {
    this.setState( { visible : !this.state.visible});
  }
}

export default AccordianItem;
