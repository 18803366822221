import { combineReducers } from 'redux'
import applications from './applicationsReducer';
import loading from './loadingReducer';
import search from './searchReducer';
import login from './loginReducer';
import cookiePolicy from './cookiePolicyReducer';

export default combineReducers({
  applications,
  loading,
  search,
  login,
  cookiePolicy
});
