import React, { Component } from 'react';
import './Server.css';

type ServerType = {
  id: number,
  text: string
}

interface ServerState {
  seconds: number,
  items: ServerType[],
  text: string
}

class Server extends Component<any, ServerState> {
  constructor(props: any) {
    super(props);
    this.state = { seconds: 0, items: [ { id: 1, text: "Hello"}, {id: 2, text:"World"}], text: '' };
  }

  interval: any;

  tick() {
    this.setState(prevState => ({
      seconds: prevState.seconds + 1
    }));
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
  }

  render() {
    return (
      <div className="Server">
        <h3>{this.props.name}</h3>
        <p> Seconds: {this.state.seconds}</p>
        {this.state.items.map(item => (
          <li key={item.id}>{item.text}</li>
        ))}
      </div>
    );
  }
}

export default Server;
