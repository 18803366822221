import React from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import { logoutState } from '../store/loginActions';

interface Props {
  dispatch: Function
}

class LogoutPage extends React.Component<Props> {
  componentWillMount() {
    this.props.dispatch(logoutState());
  }

  render() {
    return (
      <Redirect to="/" />
    );
  }
}

export default connect()(LogoutPage);
