import React, { Component } from 'react';
import logo from '../../logo.svg';
import './Header.css';

class Header extends Component {
  render() {
    return (
      <header className="Header">
        <div>
          <p className="Header-version">{process.env.REACT_APP_VERSION}</p>
          <img src={logo} className="Header-logo u-displayNone--md" alt="logo" />
          <h1 className="Header-title">Welcome to React</h1>
        </div>
      </header>
    );
  }
}

export default Header;
