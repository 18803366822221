import React, { ChangeEventHandler } from 'react';
import './Form.css';

interface Props {
  name: string,
  onChange: ChangeEventHandler<HTMLInputElement>,
  value: string
}

class Input extends React.Component<Props> {
  render() {
    return (
      <input className="form-control Input" type="text" name={this.props.name} value={this.props.value} 
        onChange={this.props.onChange} />
    );
  }
}

export default Input;
