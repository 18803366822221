import React from 'react';
import './Accordian.css';

interface Props {
  children: React.ReactNode;
}

class Accordian extends React.Component<Props> {
  render() {
    return (
      <div className="Accordian">{this.props.children}</div>
    );
  }
}

export default Accordian;
