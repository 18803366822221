import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import './Menu.css';

interface Props {
  loggedInUser: boolean
}

interface MenuItem {
  key: string,
  path: string,
  text: string
}

interface MenuState {
  menuItems: MenuItem[]
}

class Menu extends React.Component<Props, MenuState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      menuItems: [{
        key: '1',
        path: '/',
        text: 'Home'
      }, {
        key: '2',
        path: '/applyPage',
        text: 'Apply'
      }, {
        key: '3',
        path: '/searchPage',
        text: 'Search'
      }, {
        key: '4',
        path: '/credit',
        text: 'Credit'
      }, {
        key: '5',
        path: '/dragPage',
        text: 'Drag'
      }]
    };
  }
  
  render() {
    const isLoggedIn = this.props.loggedInUser !== undefined;
    return (
      <nav className='navbar-default' style={{ width: "100%" }}>
        <div className="container-fluid">
          <div className="navbar-header">
            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="myNavbar">
            <ul className="nav navbar-nav">
              {
                this.state.menuItems.map((menuItem, index) => {
                  return <li key={menuItem.key}><Link className="Link-small" to={menuItem.path}>{menuItem.text}</Link></li>
                })
              }
            </ul>
            <ul className="nav navbar-nav navbar-right">
              { isLoggedIn &&
                <li key="logout"><Link className="Link-small" to={"/logout"}><span className="glyphicon glyphicon-log-out" aria-hidden="true"></span>&nbsp;&nbsp;Logout</Link></li>
              }
              { !isLoggedIn &&
                <li key="login"><Link className="Link-small" to={"/login"}><span className="glyphicon glyphicon-log-in" aria-hidden="true"></span>&nbsp;&nbsp;Login</Link></li>
              }
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
function mapStateToProps(state: any) {
  return { loggedInUser : state.login.loggedInUser};
}

export default connect(mapStateToProps)(Menu);
