import React, { Component } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import '../App.css';
import Board from './Board';

class DragPage extends Component {
  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <Board knightPosition={[7, 4]} />
      </DndProvider>
    );
  }
}

export default DragPage;
