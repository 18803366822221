export const LOAD_APPLICATION = 'application/LOAD_APPLICATION';

function loadApplication(application: any) {
  return {
    type: LOAD_APPLICATION,
    application
  }
}

export {
  loadApplication
}