import React from 'react';
import './Address.css';

interface Props {
  id: string,
  removeHandler?: Function,
  houseNumber?: string,
  road?: string,
  town?: string,
  postcode?: string,
  county?: string,
  timeInYears?: string,
  timeInMonths?: string
}

interface AddressState {
  houseNumber: string,
  road: string,
  town: string,
  postcode: string,
  county: string,
  timeInYears: string,
  timeInMonths: string
}
class Address extends React.Component<Props, AddressState> {
  constructor(props: any) {
    super(props);
    if(props) {
      this.state = props;
    }
  }

  render() {
    return (
      <div className="Address">
        <div className="form-group row">
          <label className="col-xs-4 text-left">House/flat</label>
            <div className="col-xs-2">
              <input className="form-control form-control-sm" name="houseNumber" value={this.state.houseNumber} onChange={this.handleHouseNumberChange}/>
            </div>
            <div className="col-xs-4">
              <input className="form-control form-control-sm" name="road" value={this.state.road} onChange={this.handleRoadChange}/>
            </div>
            <div className="col-xs-2">
              <button className="btn btn-info btn-xs" name="remove" onClick={this.handleRemove}>Remove</button>
            </div>
        </div>
        <div className="form-group row">
          <label className="col-xs-4 text-left">Town</label>
          <div className="col-xs-4 text-left">
            <input className="form-control form-control-sm" name="town" value={this.state.town} placeholder="Town" onChange={this.handleTownChange}/>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-xs-4 text-left">County</label>
          <div className="col-xs-4 text-left">
            <input className="form-control form-control-sm" name="County" value={this.state.county} placeholder="County" onChange={this.handleCountyChange}/>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-xs-4 text-left">Postcode</label>
          <div className="col-xs-4 text-left">
          <input className="form-control form-control-sm" name="postcode" value={this.state.postcode} placeholder="Postcode" onChange={this.handlePostcodeChange}/>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-xs-4 text-left">Time at address</label>
          <div className="col-xs-1 text-left">
            <input className="form-control form-control-sm" name="timeInYears" value={this.state.timeInYears} onChange={this.handleTimeInYearsChange}/>
          </div>
          <div className="col-xs-1 text-left">
            <input className="form-control form-control-sm" name="timeInMonths" value={this.state.timeInMonths} onChange={this.handleTimeInMonthsChange}/>
          </div>
        </div>
      </div>
    );
  }

  handleHouseNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState( { houseNumber: event.target.value.replace(/[^0-9]/ig, '')});
  }

  handleRoadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState( { road: event.target.value});
  }

  handleTownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState( { town: event.target.value});
  }

  handleCountyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState( { county: event.target.value});
  }

  handlePostcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState( { postcode: event.target.value});
  }

  handleTimeInYearsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState( { timeInYears: event.target.value});
  }

  handleTimeInMonthsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState( { timeInMonths: event.target.value});
  }

  handleRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if( this.props.removeHandler) {
      this.props.removeHandler(event, this.props.id);
    }
  }
}

export default Address;
