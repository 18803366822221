import React from 'react';
import { DragSource } from 'react-dnd';
import './knight.css';
import { ItemTypes } from './ItemTypes';

const knightSource = {
  // Describes how the drag source reacts to the drag and drop events
  beginDrag(props) {
    console.log('beginDrag');
    return {};
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

function Knight({ connectDragSource, isDragging }) {
  return connectDragSource(
    <div style={{
      opacity: isDragging ? 0.5 : 1,
      fontSize: 48,
      fontWeight: 'bold',
      cursor: 'move'
    }}>
      ♘
    </div>
  );
}

export default DragSource(ItemTypes.KNIGHT, knightSource, collect)(Knight);
