import React from 'react';
import { connect } from 'react-redux'

import './Notification.css';
import { acceptCookiePolicy } from '../store/cookieActions';

interface Props {
  dispatch: Function,
  visible: boolean,
  text?: string
}

class Notification extends React.Component<Props> {

  render() {
    const style = {
      display: this.props.visible ? "block" : "none"
    };

    return (
      <div className="Notification" style={style}>
        <p>{this.props.text}</p>
        <button className="btn btn-primary btn-xs" onClick={this.onHandleClick}>Accept</button>
      </div>
    );
  }

  onHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.props.dispatch(acceptCookiePolicy());
  }
}

function mapStateToProps(newState: any) {
  return { visible: !newState.cookiePolicy.accepted};
}

export default connect(mapStateToProps)(Notification);
