export const LOAD_SEARCH = 'search/LOAD_SEARCH';
export const SET_ALL_RESULTS = 'search/SET_ALL';
export const FETCH_SEARCH_SUCCESS = 'search/FETCH_SEARCH_SUCCESS';

function loadSearch(results: any) {
  return {
    type: LOAD_SEARCH,
    results
  }
}

function setAllSearch(all: any) {
  return {
    type: SET_ALL_RESULTS,
    all
  }
}

export {
  loadSearch,
  setAllSearch
}
